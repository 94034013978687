import { parse, formatDistanceToNow } from 'date-fns'

export default function daysSince() {
  document.querySelectorAll('[ck-dayssince-spill').forEach((element) => {
    const dateString = element.getAttribute('ck-dayssince')

    if (dateString) {
      const difference = parseString(dateString)
      element.innerHTML = `${difference} ago`
    }
  })

  document.querySelectorAll('[ck-dayssince-ticker').forEach((element) => {
    const dateString = element.getAttribute('ck-dayssince')

    if (dateString) {
      const difference = parseString(dateString)
      element.innerHTML = `${difference} Since Last Incident`
    }
  })
}

function parseString(dateString) {
  const date = parse(dateString, 'MMMM dd, yyyy', new Date())
  const difference = formatDistanceToNow(date).replace('about', '')
  return difference
}
